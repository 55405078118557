<template>
    <v-container fluid ref="container" class="pb-0">
        <v-col cols="12" class="ma-0 pa-0">
            <v-data-table
                :headers="headers"
                :items="filterWorkOrders"
                class="elevation-0"
                :mobile-breakpoint="0"
                :loading="loading"
                disable-pagination
                hide-default-footer
                :height="`${height}px`"
                multiple-expand
                expanded.sync
                :show-expand="true"
                fixed-header
                style="overflow-y: auto;"
                item-key="partNumberId"
            >
                <template v-slot:top>
                    <v-row
                        no-gutter
                        style="background-color: #eeeeee"
                        class="mt-0 mx-0 pb-0"
                    >
                        <v-col cols="12" sm="4" class="d-flex mb-1 pb-0">
                            <h2 class="mx-4 mt-1">
                                {{
                                    $vuetify.breakpoint.smAndDown
                                        ? 'ITEMS'
                                        : 'ASSEMBLY ITEMS'
                                }}
                            </h2>
                            <v-btn
                                class="mt-1"
                                small
                                icon
                                color="primary"
                                @click="openDeleteAllItem"
                                v-if="
                                    (workOrder.status == 'inProgress' ||
                                        workOrder.status == 'rejected') &&
                                        !readOnlyWO
                                "
                            >
                                <v-icon>mdi-delete-sweep-outline</v-icon>
                            </v-btn>
                        </v-col>
                        <v-spacer />
                        <v-col
                            cols="12"
                            sm="4"
                            class="d-flex justify-end"
                            v-if="
                                (workOrder.status == 'inProgress' ||
                                    workOrder.status == 'rejected') &&
                                    !readOnlyWO
                            "
                        >
                            <v-btn
                                rounded
                                color="primary"
                                class="mx-1"
                                @click="openAddItem"
                                :disabled="loading"
                                :small="$vuetify.breakpoint.smAndDown"
                            >
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                                {{
                                    $vuetify.breakpoint.smAndDown
                                        ? ''
                                        : 'NEW ITEM'
                                }}
                            </v-btn>
                            <v-btn
                                rounded
                                color="primary"
                                class="mx-1"
                                @click="importFiles"
                                :disabled="loading"
                                :small="$vuetify.breakpoint.smAndDown"
                            >
                                <v-icon>
                                    mdi-login-variant
                                </v-icon>
                                {{
                                    $vuetify.breakpoint.smAndDown
                                        ? ''
                                        : 'IMPORT'
                                }}
                            </v-btn>
                            <v-btn
                                rounded
                                color="primary"
                                class="mx-1"
                                @click="openUploadFiles"
                                :disabled="loading"
                                :small="$vuetify.breakpoint.smAndDown"
                            >
                                <v-icon>
                                    mdi-upload
                                </v-icon>
                                {{
                                    $vuetify.breakpoint.smAndDown ? '' : 'FILES'
                                }}
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="4"
                            class="d-flex justify-end px-0"
                            style="flex-wrap: wrap;"
                            v-if="
                                !user.operatorRole &&
                                    (workOrder.status == 'approved' ||
                                        workOrder.status == 'finished')
                            "
                        >
                            <div class="d-flex justify-center pr-3">
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-1"
                                    @click="openMultipleProcesses"
                                    v-if="
                                        !user.operatorRole &&
                                            user.permissions.includes(
                                                'updateWOProcesses'
                                            ) &&
                                            workOrder.status == 'approved'
                                    "
                                    :small="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-icon>
                                        mdi-swap-vertical
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'SET PROCESSES'
                                    }}
                                </v-btn>
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-1"
                                    @click="openMultipleRegister"
                                    :small="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-icon class="mr-1">
                                        mdi-tools
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'REGISTER ITEMS'
                                    }}
                                </v-btn>
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-1"
                                    @click="openMultipleSend"
                                    :small="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-icon class="mr-1">
                                        mdi-send
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'SEND ITEMS'
                                    }}
                                </v-btn>
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-1"
                                    @click="openReceiveItems"
                                    v-if="
                                        (workOrder.status == 'approved' ||
                                            workOrder.status == 'finished') &&
                                            user.process
                                    "
                                    :small="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-icon class="mr-1">
                                        mdi-database-arrow-down-outline
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'RECEIVE ITEMS'
                                    }}
                                </v-btn>
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-1"
                                    @click="openMultipleQuality"
                                    v-if="
                                        user.permissions.includes(
                                            'markQualityRegisters'
                                        )
                                    "
                                    :small="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-icon class="mr-1">
                                        mdi-check-bold
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'QUALITY'
                                    }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <!-- HEADERS -->
                <template v-slot:[`header.processes`]="{ header }">
                    <div class="d-flex justify-center align-center">
                        <v-text-field
                            class="py-2 mt-2"
                            :label="header.text"
                            v-model="processToFilter"
                            dense
                            style="width: 80px;"
                        />
                        <v-spacer />
                        <v-menu rounded offset-y v-if="user.process">
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    depressed
                                >
                                    <v-icon>mdi-sort</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    @click="
                                        sortByAvailable = !sortByAvailable
                                        sortByProduced = false
                                        sortByDelivered = false
                                    "
                                    :class="{
                                        'selected-item': sortByAvailable,
                                    }"
                                >
                                    <v-list-item-title>
                                        Sort By Available
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="
                                        sortByAvailable = false
                                        sortByProduced = !sortByProduced
                                        sortByDelivered = false
                                    "
                                    :class="{
                                        'selected-item': sortByProduced,
                                    }"
                                >
                                    <v-list-item-title>
                                        Sort By Produced
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="
                                        sortByAvailable = false
                                        sortByProduced = false
                                        sortByDelivered = !sortByDelivered
                                    "
                                    :class="{
                                        'selected-item': sortByDelivered,
                                    }"
                                >
                                    <v-list-item-title>
                                        Sort By Delivered
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <!--ITEMS-->
                <template v-slot:[`item.id`]="{ index }">
                    <p class="my-0">
                        {{ index + 1 }}
                    </p>
                </template>
                <template v-slot:[`item.mass`]="{ item }">
                    <p class="my-0">
                        {{ getAssemblyMass(item) }}
                    </p>
                </template>
                <template v-slot:[`item.paintingArea`]="{ item }">
                    <p class="my-0">
                        {{ getAssemblyPaintingArea(item) }}
                    </p>
                </template>
                <template v-slot:[`item.finishAndUCCode`]="{ item }">
                    <p class="my-0">
                        {{
                            item.dataFinishAndUCCode &&
                                item.dataFinishAndUCCode.name
                        }}
                    </p>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                    <p class="my-0" style="max-width: 250px">
                        {{ item.description }}
                    </p>
                </template>
                <template v-slot:[`item.assemblyItems`]="{ item }">
                    <p class="my-0" style="max-width: 250px">
                        {{ item.assemblyItems ? item.assemblyItems.length : 0 }}
                    </p>
                </template>

                <template v-slot:[`item.notes`]="{ item }">
                    <p class="my-0" style="max-width: 250px">
                        {{ item.notes }}
                    </p>
                </template>
                <template v-slot:[`item.processes`]="{ item }">
                    <div
                        v-for="process in item.productionInfo"
                        :key="process.id"
                    >
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <p class="my-0 text-left" v-on="on">
                                    {{ process.name }}:
                                    {{ Math.trunc(process.available) }} -
                                    {{ process.produced || 0 }} -
                                    {{ process.delivered }}
                                </p>
                            </template>
                            <span>
                                {{ process.name }}: <br />
                                Available =
                                {{ Math.trunc(process.available || 0) }} ({{
                                    (
                                        ((process.received || 0) * 100) /
                                        item.quantity
                                    ).toFixed(2)
                                }}%)<br />
                                Produced =
                                {{ process.produced || 0 }} ({{
                                    (
                                        ((process.produced || 0) * 100) /
                                        item.quantity
                                    ).toFixed(2)
                                }}%)<br />
                                Delivered =
                                {{ process.delivered }} ({{
                                    (
                                        ((process.delivered || 0) * 100) /
                                        item.quantity
                                    ).toFixed(2)
                                }}%)
                            </span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <div>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #2b81d6;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateQualityPending(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #2fbf56;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateReleasedQty(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #ffa41d;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateReprocessQty(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #FF2610;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateRejectedQty(item) }}
                                    </p>
                                </v-btn>
                            </template>
                            <span>
                                Pending :
                                {{ calculateQualityPending(item) || 0 }}
                                <br />
                                Released :
                                {{ calculateReleasedQty(item) }} <br />
                                Reprocess :
                                {{ calculateReprocessQty(item) }} <br />
                                Rejected :
                                {{ calculateRejectedQty(item) }} <br />
                            </span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:[`item.files`]="{ item }">
                    <div
                        class="d-flex justify-center my-0"
                        v-if="item.files != undefined"
                    >
                        <a
                            v-if="item.files.includes(`${item.partNumber}.pdf`)"
                            class="mx-1"
                            @click="loadFiles(item, 'pdf')"
                        >
                            PDF
                        </a>
                        <a
                            v-if="item.files.includes(`${item.partNumber}.dxf`)"
                            class="mx-1"
                            @click="loadFiles(item, 'dxf')"
                        >
                            DXF
                        </a>
                        <a
                            v-if="item.files.includes(`${item.partNumber}.stp`)"
                            class="mx-1"
                            @click="loadFiles(item, 'stp')"
                        >
                            STP
                        </a>
                    </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div>
                        <v-btn
                            small
                            icon
                            color="primary"
                            hide-details
                            @click="openAssemblyForm(item)"
                            v-if="
                                workOrder.status == 'inProgress' ||
                                    workOrder.status == 'rejected'
                            "
                        >
                            <v-icon>mdi-file-tree-outline</v-icon>
                        </v-btn>
                        <v-menu rounded offset-y>
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    depressed
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    @click="editItem(item)"
                                    v-if="
                                        (workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected') &&
                                            !readOnlyWO
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Edit Item
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="openAddItemFiles(item)"
                                    v-if="
                                        (workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected') &&
                                            !readOnlyWO
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-upload</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Add Item Files
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="openDeleteItem(item)"
                                    v-if="
                                        (workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected') &&
                                            !readOnlyWO
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-trash-can</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Delete Item
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="copyItem(item)"
                                    v-if="
                                        (workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected') &&
                                            !readOnlyWO
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Copy Item
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="openArrangeProcesses(item)"
                                    v-if="
                                        workOrder.status == 'approved' &&
                                            !readOnlyWO &&
                                            !user.operatorRole &&
                                            user.permissions.includes(
                                                'updateWOProcesses'
                                            )
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-swap-vertical</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Arrange Processes
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="registerProduction(item)"
                                    v-if="
                                        (workOrder.status == 'approved' ||
                                            workOrder.status == 'finished') &&
                                            !readOnlyWO &&
                                            item &&
                                            activateActions(item)
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-tools</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Register Production
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="sendToPrevProcess(item)"
                                    v-if="
                                        (workOrder.status == 'approved' ||
                                            workOrder.status == 'finished') &&
                                            !readOnlyWO &&
                                            item &&
                                            item.processes &&
                                            item.processes.length > 0 &&
                                            activateActions(item) &&
                                            item.processes[0] != user.process.id
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon
                                            >mdi-arrow-left-circle-outline</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Return To Previous Process
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="sendToNextProcess(item)"
                                    v-if="
                                        (workOrder.status == 'approved' ||
                                            workOrder.status == 'finished') &&
                                            !readOnlyWO &&
                                            item &&
                                            item.processes &&
                                            item.processes.length > 1 &&
                                            activateActions(item)
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-send</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Send To Next Process
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="registerQuality(item)"
                                    v-if="
                                        !readOnlyWO &&
                                            item &&
                                            item.processes &&
                                            item.processes.length > 0 &&
                                            activateActions(item) &&
                                            user.permissions.includes(
                                                'markQualityRegisters'
                                            ) &&
                                            (workOrder.status == 'approved' ||
                                                workOrder.status == 'finished')
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-check-bold</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Register Quality
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="openProductionLog(item)"
                                    v-if="
                                        item &&
                                            item.processes &&
                                            item.processes.length > 0 &&
                                            workOrder.status != 'inProgress'
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-book-cog-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Production Log
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="openDeliveryLog(item)"
                                    v-if="
                                        item &&
                                            item.processes &&
                                            workOrder.status != 'inProgress'
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon
                                            >mdi-book-arrow-right-outline</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Delivery Log
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="openQualityLog(item)"
                                    v-if="
                                        item &&
                                            item.processes &&
                                            item.processes.length > 0 &&
                                            workOrder.status != 'inProgress'
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-book-check-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Quality Log
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="openDeleteItem(item)"
                                    v-if="
                                        workOrder.status == 'approved' &&
                                            !readOnlyWO &&
                                            permissionToCancelWO &&
                                            (!item.production ||
                                                item.production.length == 0)
                                    "
                                    :disabled="
                                        item.production &&
                                            item.production.length > 0
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-trash-can</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Delete Item
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <!--EXPAND TABLE-->
                <template v-slot:expanded-item="{ headers, item: assembly }">
                    <td
                        :colspan="headers.length"
                        class="py-4 ma-0 pl-10 pr-10 outlined"
                        style="background: white"
                    >
                        <div>
                            <v-data-table
                                :headers="itemsHeaders"
                                :items="assembly.assemblyItems"
                                class="ma-0 pa-0 elevation-0"
                                :mobile-breakpoint="0"
                                disable-pagination
                                hide-default-footer
                                style="width: auto !important;"
                            >
                                <!-- ITEMS -->
                                <template v-slot:[`item.id`]="{ index }">
                                    <p class="my-0">
                                        {{ index + 1 }}
                                    </p>
                                </template>
                                <template v-slot:[`item.partNumber`]="{ item }">
                                    <p class="my-0">
                                        {{ item.partNumber }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.description`]="{ item }"
                                >
                                    <p class="my-0">
                                        {{ item.description }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.qtyPerAssembly`]="{ item }"
                                >
                                    <p class="my-0">
                                        {{ item.qtyPerAssembly }}
                                    </p>
                                </template>
                                <template v-slot:[`item.mass`]="{ item }">
                                    <p class="my-0">
                                        {{ item.mass }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.paintingArea`]="{ item }"
                                >
                                    <p class="my-0">
                                        {{ item.paintingArea }}
                                    </p>
                                </template>
                                <template v-slot:[`item.totalQty`]="{ item }">
                                    <p class="my-0">
                                        {{
                                            assembly.quantity *
                                                item.qtyPerAssembly
                                        }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.receivedQty`]="{
                                        item,
                                    }"
                                >
                                    <p class="my-0">
                                        {{ getReceivedQty(item, assembly) }}
                                    </p>
                                </template>
                                <template v-slot:[`item.notes`]="{ item }">
                                    <p class="my-0">
                                        {{
                                            getItemInfo(
                                                item.partNumberId,
                                                item.workOrderId
                                            ).notes
                                        }}
                                    </p>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <div>
                                        <v-btn
                                            small
                                            icon
                                            hide-details
                                            @click="
                                                openAssemblyItemDetails(
                                                    item,
                                                    assembly
                                                )
                                            "
                                        >
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                        </div>
                    </td>
                </template>

                <!--FOOTER-->
                <template v-slot:[`body.append`]>
                    <tr>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold text-center"></td>
                        <td class="font-weight-bold text-center">
                            TOTAL
                        </td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold text-center">
                            {{ getTotalMass() }} (kgs)
                        </td>
                        <td class="font-weight-bold text-center">
                            {{ getTotalPaintingArea() }} (m2)
                        </td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold text-center">
                            {{ totalQuantity }}
                        </td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td
                            class="font-weight-bold"
                            v-if="
                                workOrder.status == 'approved' ||
                                    workOrder.status == 'closed'
                            "
                        ></td>
                    </tr>
                </template>
            </v-data-table>
        </v-col>
        <!--Assembly Form-->
        <v-dialog
            :retain-focus="false"
            v-model="assemblyForm"
            persistent
            max-width="1000px"
        >
            <AssemblyForm
                v-if="assemblyForm"
                :workOrder="workOrder"
                :user="user"
                :settings="settingProcesses"
                :originalItems="projectWOItems"
                :parentItem="selectedItem"
                @closeDialog="closeAssemblyForm"
            />
        </v-dialog>
        <!-- Assembly Item Details -->
        <v-dialog
            :retain-focus="false"
            v-model="assemblyItemDetails"
            persistent
            max-width="900px"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5"
                    ><v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="closeAssemblyItemDetails"
                    >
                        <v-icon>mdi-close</v-icon> </v-btn
                    >Assembly Item
                </v-card-title>
                <v-divider class="ml-4 mr-5" />
                <v-card-text>
                    <v-data-table
                        :headers="itemsDetailsHeaders"
                        :items="itemsToShow"
                        class="mt-4 pa-0 elevation-0"
                        :mobile-breakpoint="0"
                        disable-pagination
                        hide-default-footer
                        style="width: auto !important;"
                    >
                        <!-- ITEMS -->
                        <template v-slot:[`item.id`]="{ index }">
                            <p class="my-0">
                                {{ index + 1 }}
                            </p>
                        </template>
                        <template v-slot:[`item.workOrder`]="{ item }">
                            <p class="my-0">
                                {{
                                    getItemInfo(
                                        item.partNumberId,
                                        item.workOrderId
                                    ).workOrder
                                }}
                            </p>
                        </template>
                        <template v-slot:[`item.partNumber`]="{ item }">
                            <p class="my-0">
                                {{
                                    getItemInfo(
                                        item.partNumberId,
                                        item.workOrderId
                                    ).partNumber
                                }}
                            </p>
                        </template>
                        <template v-slot:[`item.description`]="{ item }">
                            <p class="my-0">
                                {{
                                    getItemInfo(
                                        item.partNumberId,
                                        item.workOrderId
                                    ).description
                                }}
                            </p>
                        </template>
                        <template v-slot:[`item.qtyPerAssembly`]="{ item }">
                            <p class="my-0">
                                {{ item.qtyPerAssembly }}
                            </p>
                        </template>
                        <template v-slot:[`item.totalQty`]="{ item }">
                            <p class="my-0">
                                {{
                                    selectedAssembly.quantity *
                                        item.qtyPerAssembly
                                }}
                            </p>
                        </template>
                        <template
                            v-slot:[`item.receivedQty`]="{
                                item,
                            }"
                        >
                            <p class="my-0">
                                {{ getReceivedQty(item, selectedAssembly) }}
                            </p>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <div>
                                <v-btn
                                    small
                                    icon
                                    hide-details
                                    @click="
                                        deleteAssemblyItem(
                                            item,
                                            selectedAssembly
                                        )
                                    "
                                    v-if="
                                        (workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected') &&
                                            getReceivedQty(
                                                item,
                                                selectedAssembly
                                            ) == 0
                                    "
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn
                                    small
                                    icon
                                    hide-details
                                    @click="
                                        openEditAssemblyItem(
                                            item,
                                            selectedAssembly
                                        )
                                    "
                                    v-if="
                                        workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected'
                                    "
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Assembly Item Dialog -->
        <v-dialog
            :retain-focus="false"
            v-model="editAssemblyItemDialog"
            persistent
            max-width="400px"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5"
                    >Assembly Item
                    {{ selectedAssemblyItem.partNumber }}</v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text>
                    <v-form v-model="valid" class="mt-3 mr-2">
                        <v-col cols="12" class="pt-0 pb-1 px-0">
                            <v-text-field
                                v-model="selectedAssemblyItem.qtyPerAssembly"
                                prepend-icon="mdi-file-tree-outline"
                                prefix="Qty Per Assembly:"
                                type="number"
                                required
                                class="ma-0"
                                :suffix="`x${selectedItem.quantity}`"
                                :rules="[() => maxValue()]"
                            />
                        </v-col>
                        <v-row class="mt-0">
                            <v-col cols="6" class="d-flex align-center pb-3">
                                <div class="d-flex align-center">
                                    <v-icon class="pr-2"
                                        >mdi-database-outline</v-icon
                                    >
                                    <p class="mb-0">
                                        Available Qty: {{ availableQty }}
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="6" class="d-flex align-center pb-3">
                                <div class="d-flex align-center">
                                    <v-icon class="pr-2"
                                        >mdi-database-outline</v-icon
                                    >
                                    <p class="mb-0">
                                        Total Qty:
                                        {{
                                            selectedItem.quantity *
                                                selectedAssemblyItem.qtyPerAssembly
                                        }}
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pb-3 pt-0">
                    <v-btn
                        text
                        color="secondary"
                        @click="closeEditAssemblyItem"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        :disabled="
                            !valid ||
                                this.initialQty ==
                                    selectedAssemblyItem.qtyPerAssembly
                        "
                        @click="updateAssemblyItem"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import qrious from 'qrious'
import _ from 'lodash'
import { loadImage } from '@/helpers/imageHandler.js'

export default {
    name: 'AssemblyItems',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        readOnlyWO: {
            type: Boolean,
            default: () => false,
        },
        height: {
            type: Number,
            default: () => 0,
        },
        settingFinish: { type: Object, default: () => {} },
        partNumbers: { type: Array, default: () => [] },
    },
    components: {
        AssemblyForm: () => import('@/components/WorkOrders/AssemblyForm.vue'),
    },
    data: () => ({
        selectedItem: {},
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
                width: 50,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PAINTING AREA',
                value: 'paintingArea',
                align: 'center',
                sortable: false,
            },
            {
                text: 'FINISH & UC CODE',
                value: 'finishAndUCCode',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ASSEMBLY ITEMS',
                value: 'assemblyItems',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'PROCESSES',
                value: 'processes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'FILES',
                value: 'files',
                align: 'center',
                sortable: false,
            },
            {
                text: 'OC',
                value: 'oc',
                align: 'left',
                sortable: false,
            },
            {
                text: 'POSITION',
                value: 'costPosition',
                align: 'left',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        itemsHeaders: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
                width: 50,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PAINTING AREA',
                value: 'paintingArea',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY PER ASSEMBLY',
                value: 'qtyPerAssembly',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL QTY',
                value: 'totalQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RECEIVED QTY',
                value: 'receivedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
        ],
        itemsDetailsHeaders: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
                width: 50,
            },
            {
                text: 'WORK ORDER',
                value: 'workOrder',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'QTY PER ASSEMBLY',
                value: 'qtyPerAssembly',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL QTY',
                value: 'totalQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RECEIVED QTY',
                value: 'receivedQty',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        settingProcesses: undefined,
        file: null,
        user: {},
        permissionToCancelWO: false,
        permissionToApproveWO: false,
        processToShow: undefined,
        showProcesses: false,
        packingDialog: false,
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
        prevProcessDialog: false,
        qualityDialog: false,
        multipleQualityDialog: false,
        viewType: null,
        itemLogDialog: false,
        processToFilter: undefined,
        sortByAvailable: false,
        sortByProduced: false,
        sortByDelivered: false,
        assemblyForm: false,
        projectWorkOrders: [],
        projectWOItems: [],
        selectedAssemblyItem: {},
        editAssemblyItemDialog: false,
        availableQty: 0,
        valid: false,
        initialQty: 0,
        partNumbersNotFound: [],
        openItemFiles: false,
        woItems: [],
        itemsToShow: [],
        selectedAssembly: {},
        assemblyItemDetails: false,
    }),
    computed: {
        filterWorkOrders() {
            let conditions = []
            let items = this.woItems
            if (this.processToFilter) {
                conditions.push(this.filterByProcess)
            }
            if (conditions.length > 0) {
                return items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }
            if (
                (this.sortByAvailable ||
                    this.sortByProduced ||
                    this.sortByDelivered) &&
                this.user.process
            ) {
                const auxItems = _.cloneDeep(items)
                const sortedItems = this.sort(auxItems)
                return sortedItems
            } else if (
                !this.sortByAvailable &&
                !this.sortByProduced &&
                !this.sortByDelivered &&
                this.user.process
            ) {
                items.sort((a, b) => {
                    const last4DigitsA = parseInt(a.partNumber.slice(-4), 10)
                    const last4DigitsB = parseInt(b.partNumber.slice(-4), 10)
                    return last4DigitsA - last4DigitsB
                })
                return items
            }

            return items
        },

        totalMass() {
            return this.workOrder.items
                ? this.workOrder.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.mass * item.quantity,
                      0
                  )
                : 0
        },
        totalPaintingArea() {
            return this.workOrder.items
                ? this.workOrder.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.paintingArea * item.quantity,
                      0
                  )
                : 0
        },
        totalQuantity() {
            return this.workOrder.items
                ? this.workOrder.items.reduce(
                      (accumulator, item) => accumulator + +item.quantity,
                      0
                  )
                : 0
        },
    },
    watch: {
        workOrder: async function() {
            this.formatWOItems()
        },
    },
    async mounted() {
        try {
            this.loading = true
            // set project manager
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.projectWorkOrders = await API.getWorkOrdersByProject({
                projectId: this.workOrder.projectId,
                includeClosed: false,
            })
            this.projectWorkOrders.forEach(wo => {
                wo = this.formatItems(wo)
                if (wo.items) {
                    wo.items.forEach(item => {
                        if (wo.status == 'approved' || wo.status == 'sent') {
                            this.projectWOItems.push({
                                workOrder: wo.code,
                                type: wo.assemblyWorkOrder
                                    ? 'Assembly'
                                    : 'Production',
                                availableQty:
                                    Number(item.quantity) -
                                    Number(item.qtyInAssembly || 0),
                                workOrderId: wo.id,
                                ...item,
                            })
                        }
                    })
                }
            })
            this.formatWOItems()
            // retrieve permission to cancel a WO
            this.permissionToCancelWO = user.permissions.includes('cancelWO')
            // qr code
            new qrious({
                element: document.getElementById('qr'),
                value: `${process.env.VUE_APP_CLIENT_HOST}/work-orders/main-work-orders?orderId=${this.workOrder.id}`,
            })
            if (user.permissions.includes('watchProcesses')) {
                this.showProcesses = true
            } else if (user.process) {
                this.processToShow = user.process
                this.showProcesses = true
            }

            if (
                this.workOrder.status == 'approved' ||
                this.workOrder.status == 'closed'
            ) {
                this.headers.splice(9, 0, {
                    text: 'STATUS',
                    value: 'status',
                    align: 'center',
                    sortable: false,
                    width: 100,
                })
            }

            if (
                this.workOrder.status == 'inProgress' ||
                this.workOrder.status == 'rejected'
            ) {
                this.itemsDetailsHeaders.push({
                    text: 'ACTIONS',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                })
            }
            this.itemsHeaders.push({
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
            console.log(error)
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        formatWOItems() {
            //format work order items
            this.woItems = this.workOrder.items ? this.workOrder.items : []
            for (const item of this.woItems) {
                if (item.assemblyItems) {
                    const assemblyItems = _.cloneDeep(item.assemblyItems)
                    item.assemblyItems = _.cloneDeep([])
                    assemblyItems.forEach(ai => {
                        ai.itemDeliveries = this.getItemInfo(
                            ai.partNumberId,
                            ai.workOrderId
                        ).deliveries
                        const foundAssemblyItem = item.assemblyItems.find(
                            assemblyItem =>
                                assemblyItem.partNumber &&
                                assemblyItem.partNumber ==
                                    this.getItemInfo(
                                        ai.partNumberId,
                                        ai.workOrderId
                                    ).partNumber
                        )
                        if (!foundAssemblyItem) {
                            const partNumbersList = assemblyItems.filter(
                                i =>
                                    this.getItemInfo(
                                        ai.partNumberId,
                                        ai.workOrderId
                                    ).partNumber ==
                                    this.getItemInfo(
                                        i.partNumberId,
                                        i.workOrderId
                                    ).partNumber
                            )

                            const totalQtyPerAssembly = partNumbersList.reduce(
                                (accum, part) => accum + part.qtyPerAssembly,
                                0
                            )

                            let totalDeliveries = []
                            partNumbersList.forEach(p => {
                                if (p.deliveries) {
                                    totalDeliveries.push(...p.deliveries)
                                }
                            })
                            const newItem = {
                                partNumber: this.getItemInfo(
                                    ai.partNumberId,
                                    ai.workOrderId
                                ).partNumber,
                                items: partNumbersList,
                                description: this.getItemInfo(
                                    ai.partNumberId,
                                    ai.workOrderId
                                ).description,
                                mass: this.getItemInfo(
                                    ai.partNumberId,
                                    ai.workOrderId
                                ).mass,
                                paintingArea: this.getItemInfo(
                                    ai.partNumberId,
                                    ai.workOrderId
                                ).paintingArea,
                                qtyPerAssembly: totalQtyPerAssembly,
                                deliveries: totalDeliveries,
                            }
                            item.assemblyItems.push(newItem)
                        }
                    })
                }
            }
        },
        editItem(item) {
            this.$emit('editItem', item)
        },
        async copyItem(item) {
            this.$emit('copyItem', item)
        },
        openDeleteItem(item) {
            this.$emit('openDeleteItem', item)
        },
        openDeleteAllItem() {
            this.$emit('openDeleteAllItem')
        },
        async loadFiles(item, extension) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/workOrders/${this.workOrder.id}/items`,
                    item.partNumber + '.' + extension
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openUploadFiles() {
            this.$emit('openUploadFiles')
        },
        openAddItemFiles(item) {
            this.$emit('openAddItemFiles', item)
        },
        openAddItem() {
            this.$emit('openAddItem')
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        openArrangeProcesses(item) {
            this.$emit('openArrangeProcesses', item)
        },
        sendToPrevProcess(item) {
            this.$emit('sendToPrevProcess', item)
        },
        sendToNextProcess(item) {
            this.$emit('sendToNextProcess', item)
        },
        registerProduction(item) {
            this.$emit('registerProduction', item)
        },
        activateActions(item) {
            return (
                !this.loading &&
                item.processes &&
                this.user.process &&
                item.processes.includes(this.user.process.id)
            )
        },
        openMultipleProcesses() {
            this.$emit('openMultipleProcesses')
        },
        openMultipleRegister() {
            this.$emit('openMultipleRegister')
        },
        openMultipleSend() {
            this.$emit('openMultipleSend')
        },
        registerQuality(item) {
            this.$emit('registerQuality', item)
        },
        openMultipleQuality() {
            this.$emit('openMultipleQuality')
        },
        calculateQualityPending(item) {
            try {
                let pendingQty = 0
                if (item.processes) {
                    let lastProcessIndex = item.processes.length - 1
                    if (this.workOrder.assemblyWorkOrder != undefined) {
                        lastProcessIndex =
                            item.processes.findIndex(
                                p => p == this.qualityProcess.id
                            ) - 1
                    }
                    if (lastProcessIndex > -1) {
                        const lastProductionProcess =
                            item.processes[lastProcessIndex]
                        if (item.production) {
                            const production = item.production.filter(
                                entry =>
                                    entry.process.id == lastProductionProcess
                            )
                            if (production.length > 0) {
                                pendingQty = production.reduce(
                                    (accumulator, entry) =>
                                        accumulator + entry.qty,
                                    0
                                )
                            }
                        }
                        if (item.quality) {
                            const rejected = item.quality.reduce(
                                (accumulator, entry) =>
                                    accumulator + (entry.rejectQty || 0),
                                0
                            )
                            const released = item.quality.reduce(
                                (accumulator, entry) =>
                                    accumulator + (entry.releaseQty || 0),
                                0
                            )
                            pendingQty -=
                                Number(released || 0) + Number(rejected || 0)
                        }
                    }
                }

                return pendingQty
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReleasedQty(item) {
            try {
                let released = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    released += Number(value || 0)
                }
                return released
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReprocessQty(item) {
            try {
                let reprocess = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.reprocessQty || 0),
                        0
                    )
                    reprocess += Number(value || 0)
                }
                return reprocess
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateRejectedQty(item) {
            try {
                let rejected = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    rejected += Number(value || 0)
                }
                return rejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openProductionLog(item) {
            this.$emit('openProductionLog', item)
        },
        openDeliveryLog(item) {
            this.$emit('openDeliveryLog', item)
        },
        openQualityLog(item) {
            this.$emit('openQualityLog', item)
        },
        filterByProcess(item) {
            let processesString = ''
            item.dataProcesses.forEach(process => {
                if (item.processesToShow.includes(process.id)) {
                    processesString += ` ${process.name}`
                }
            })
            return processesString
                .toLowerCase()
                .includes(this.processToFilter.toLowerCase())
        },
        sort(items) {
            const process = this.user.process.id

            if (this.sortByAvailable) {
                items.sort((a, b) => {
                    const productionA = a.productionInfo.find(
                        p => p.id === process
                    )
                    const productionB = b.productionInfo.find(
                        p => p.id === process
                    )
                    return (
                        (productionB?.available || 0) -
                        (productionA?.available || 0)
                    )
                })
            } else if (this.sortByProduced) {
                items.sort((a, b) => {
                    const productionA = a.productionInfo.find(
                        p => p.id === process
                    )
                    const productionB = b.productionInfo.find(
                        p => p.id === process
                    )
                    return (
                        (productionB?.produced || 0) -
                        (productionA?.produced || 0)
                    )
                })
            } else if (this.sortByDelivered) {
                items.sort((a, b) => {
                    const productionA = a.productionInfo.find(
                        p => p.id === process
                    )
                    const productionB = b.productionInfo.find(
                        p => p.id === process
                    )
                    return (
                        (productionB?.delivered || 0) -
                        (productionA?.delivered || 0)
                    )
                })
            }
            return items
        },
        closeDialog() {
            this.$emit('close')
        },
        openAssemblyForm(item) {
            this.assemblyForm = true
            this.selectedItem = _.cloneDeep(item)
        },
        closeAssemblyForm() {
            this.assemblyForm = false
            this.selectedItem = {}
        },
        getItemInfo(partNumberId, workOrderId) {
            let item = {
                workOrder: '',
                partNumber: '',
                description: '',
                notes: '',
                mass: 0,
                paintingArea: 0,
                deliveries: [],
            }
            const index = this.projectWOItems.findIndex(
                i =>
                    i.partNumberId == partNumberId &&
                    i.workOrderId == workOrderId
            )
            if (index > -1) {
                item = this.projectWOItems[index]
            }
            return item
        },
        async deleteAssemblyItem(item, parent) {
            try {
                this.loading = true
                await API.removeAssemblyItem({
                    assemblyPartNumberId: item.partNumberId,
                    workOrderId: this.workOrder.id,
                    partNumberId: parent.partNumberId,
                })
                this.closeAssemblyItemDetails()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateAssemblyItem() {
            try {
                this.loading = true
                await API.updateAssemblyItem({
                    item: {
                        partNumberId: this.selectedAssemblyItem.partNumberId,
                        qtyPerAssembly: this.selectedAssemblyItem
                            .qtyPerAssembly,
                        workOrderId: this.selectedAssemblyItem.workOrderId,
                    },
                    workOrderId: this.workOrder.id,
                    partNumberId: this.selectedItem.partNumberId,
                })
                this.closeAssemblyItemDetails()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openEditAssemblyItem(item, parent) {
            this.selectedItem = _.cloneDeep(parent)
            this.selectedAssemblyItem = _.cloneDeep(item)
            const woItem = this.projectWOItems.find(i => i.id == item.itemId)
            this.availableQty = 0
            if (woItem) {
                this.initialQty = item.qtyPerAssembly
                this.availableQty =
                    woItem.quantity -
                    woItem.qtyInAssembly +
                    item.qtyPerAssembly * parent.quantity
            }

            this.editAssemblyItemDialog = true
        },
        closeEditAssemblyItem() {
            this.selectedItem = {}
            this.selectedAssemblyItem = {}
            this.availableQty = 0
            this.editAssemblyItemDialog = false
        },
        maxValue() {
            const totalValue =
                Number(this.selectedAssemblyItem.qtyPerAssembly) *
                Number(this.selectedItem.quantity)

            if (totalValue > this.availableQty) {
                return `The quantity selected cannot be greater than ${Math.floor(
                    Number(this.availableQty) /
                        Number(this.selectedItem.quantity)
                )}`
            } else if (this.selectedAssemblyItem.qtyPerAssembly <= 0) {
                return 'The value must be positive'
            } else {
                return true
            }
        },
        getReceivedQty(item, assembly) {
            let receivedQty = 0
            if (item.deliveries) {
                receivedQty = item.deliveries.reduce(
                    (accumulator, delivery) =>
                        accumulator +
                        (delivery.nextProcess.id == assembly.processes[0]
                            ? delivery.qty
                            : 0),
                    0
                )
            }
            return receivedQty
        },
        getAssemblyMass(item) {
            let totalMass = 0
            if (item.assemblyItems) {
                totalMass = item.assemblyItems.reduce(
                    (accumulator, assemblyItem) =>
                        accumulator +
                        assemblyItem.qtyPerAssembly * (assemblyItem.mass || 0),
                    0
                )
            }
            return totalMass
        },
        getAssemblyPaintingArea(item) {
            let totalPaintingArea = 0
            if (item.assemblyItems) {
                totalPaintingArea = item.assemblyItems.reduce(
                    (accumulator, assemblyItem) =>
                        accumulator +
                        assemblyItem.qtyPerAssembly *
                            (assemblyItem.paintingArea || 0),
                    0
                )
            }
            return totalPaintingArea
        },
        getTotalMass() {
            let totalMassWo = 0
            if (this.workOrder.items && this.workOrder.items.length > 0) {
                this.workOrder.items.forEach(item => {
                    totalMassWo += this.getAssemblyMass(item) * item.quantity
                })
            }
            return totalMassWo
        },
        getTotalPaintingArea() {
            let totalPaintingAreaWo = 0
            if (this.workOrder.items && this.workOrder.items.length > 0) {
                this.workOrder.items.forEach(item => {
                    totalPaintingAreaWo +=
                        this.getAssemblyPaintingArea(item) * item.quantity
                })
            }
            return totalPaintingAreaWo
        },
        importFiles() {
            this.$emit('importFiles')
        },
        openAssemblyItemDetails(item, assembly) {
            this.itemsToShow = _.cloneDeep(item.items)
            this.selectedAssembly = _.cloneDeep(assembly)
            this.assemblyItemDetails = true
        },
        closeAssemblyItemDetails() {
            this.itemsToShow = []
            this.selectedAssembly = null
            this.assemblyItemDetails = false
        },
        openReceiveItems() {
            this.$emit('openReceiveItems')
        },
        formatItems(workOrder) {
            if (
                !(
                    (workOrder.status == 'closed' ||
                        workOrder.status == 'cancelled') &&
                    !workOrder.partNumbers
                )
            ) {
                delete workOrder.items
                workOrder.items = []
            }
            if (workOrder.partNumbers) {
                Object.keys(workOrder.partNumbers).forEach(partNumberId => {
                    let item = {
                        partNumberId,
                    }
                    Object.keys(workOrder.partNumbers[partNumberId]).forEach(
                        key => {
                            if (key == 'assemblyItems') {
                                let assemblyItems = []
                                Object.keys(
                                    workOrder.partNumbers[partNumberId][key]
                                ).forEach(aiKey => {
                                    assemblyItems.push({
                                        partNumberId: aiKey,
                                        ...workOrder.partNumbers[partNumberId][
                                            key
                                        ][aiKey],
                                    })
                                })
                                item[key] = assemblyItems
                            } else {
                                item[key] =
                                    workOrder.partNumbers[partNumberId][key]
                            }
                        }
                    )
                    workOrder.items.push(item)
                })
            }
            return workOrder
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}

.selected-item {
    background-color: #dff0ff;
}
</style>
